import React from 'react';
import { RouteComponentProps } from '@reach/router';
import PageHero from '../shared/page-hero';
import Container from '../shared/page-container';
import 'styles/_global.scss';
import Endpoint from './images/endpoint.png';
import Query from './images/query.png';
import Atugif from './images/atu.gif';
import TwoImages from '../shared/two-column-image';
import Image from 'shared/image';
import Title from 'shared/title';

export default (props: RouteComponentProps) => (
  <div className="page">
    <Title title="ATU" />
    <PageHero
      title="WordPress Frontend and Backend"
      images="atu"
      subtitle="Atheletic Transition University"
    />
    <Container>
      <div className="content">
        <h3 className="project-heading">
          Single page WordPress theme built from the ground up.
        </h3>
        <p>
          <strong>Athlete Transition U (ATU)</strong> is an organization that
          provides professional athletes the education to get them into a
          successful business career off-the-field.
        </p>
        <h4 className="project-heading">Building a Responsive Layout</h4>
        <p>
          The layout was created using a mobile-first methodology, meaning the
          smallest layout was created first, and then built upon using media
          queries to change the layout for larger screen sizes. My goal is to
          make my SCSS or POSTCSS files clean and easy to read. I use shorthands
          when I can and use variables for colors, fonts, and transitions. In
          previous experiences using variables helps making future changes
          easier. An example would be when a client wants to update their colors
          or fonts for their brand. When it comes to nesting I follow the
          Inception Rule and never nest more that 3 levels. This helps me from
          writing DOM specific styles and keeps the scss files maintainable.
        </p>
        <div className="image-container">
          <Image path="/projects/atu" name="atu-mobile" alt="ATU Demo" />
        </div>

        <h4 className="project-heading">Forms</h4>
        <p>
          I used gravity forms which has an “Enable Conditional Logic” setting
          that lets you create rules to dynamically display or hide this field
          based on values from another field. With this I was able to show the
          appropriate form for the user depending on what option they chose.
        </p>
        <h4 className="project-heading">REST API</h4>
        <p>
          The videos section on the site uses the REST api with fetch to handle
          loading in data. This section is loaded from a WordPress post type. I
          created a custom endpoint utilizing WP_Query, that is requested using
          JavaScript when you click load more. This was a fun little piece to
          the site, and later on I learned why something like React or Vue could
          make this much easier than vanilla JS.
        </p>
      </div>
      <TwoImages
        first={Endpoint}
        firstText="ATU Code Snippet"
        second={Query}
        secondText="ATU Code Snippet"
      />
      <div className="content">
        <h4 className="project-heading">WP Customizer</h4>
        <p>
          The majority of this site is editable in the customizer. If possible,
          I try to let the client edit everything from within this section. With
          ATU, the client can edit each section of the page in the customizer
          and live preview it.
        </p>
        <img src={Atugif} alt="ATU Customizer" className="gif" />
      </div>
    </Container>
  </div>
);

import React from "react";
import "./footer.scss";
import { RouteComponentProps } from "@reach/router";

export default (props: RouteComponentProps) => (
  <footer className="footer">
    <nav className="footer-navigation">
      <a
        href="https://www.linkedin.com/in/jeanie-orejarena-b2a110a8/"
        className="footer-link"
        target="_blank"
        rel="noopener"
      >
        LinkedIn
      </a>
      <a href="/JeanieOrejarenaResume2021.pdf" className="footer-link">
        Resume
      </a>
    </nav>
  </footer>
);

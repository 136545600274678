import React from 'react';
import { RouteComponentProps } from '@reach/router';
import PageHero from '../shared/page-hero';
import Container from '../shared/page-container';
import Image from 'shared/image';
import 'styles/_global.scss';
import rsegif from './images/rse.gif';
import Title from 'shared/title';

export default (props: RouteComponentProps) => (
  <div className="page">
    <Title title="RSE" />
    <PageHero
      title="WordPress Frontend and Backend"
      images="rse"
      subtitle="RSE Ventures"
    />
    <Container>
      <React.Fragment>
        <div className="content">
          <h3 className="project-heading">
            Marketing website I helped bring from start to finish in two weeks.
          </h3>
          <p>
            <strong>RSE</strong> is a private investment firm for many
            companies, so it was important to have a site that felt pristine. I
            built this entire site, front to backend.
          </p>
          <a href="https://rseventures.com/" className="site-link">
            Visit Site
          </a>
          <h4 className="project-heading">CSS</h4>
          <p>
            The layout was created using a mobile-first methodology, meaning the
            smallest layout was created first, and then built upon using media
            queries to change the layout for larger screen sizes. My goal is to
            make my SCSS or POSTCSS files clean and easy to read. I use
            shorthands when I can and use variables for colors, fonts, and
            transitions. In previous experiences using variables helps making
            future changes easier. An example would be when a client wants to
            update their colors or fonts for their brand. When it comes to
            nesting I follow the Inception Rule and never nest more that 3
            levels. This helps me from writing DOM specific styles and keeps the
            scss files maintainable.
          </p>
          <div className="image-container">
            <Image path="/projects/rse" name="rse-mobile" alt="RSE Demo" />
          </div>
          <h4 className="project-heading">Twitter</h4>
          <p>
            The news section of this site uses the twitter api to grab their
            latest tweets. This was a fun experience since it was my first time
            working with their api. Learning a bit about oauth and how to
            authenticate was a challenge at first.
          </p>
          <h4 className="project-heading">WordPress Customizer</h4>
          <p>
            The majority of this site is editable in the customizer. If
            possible, I try to let the client edit everything from within this
            section. For this site I installed a composer package that lets the
            client curate their post types and put them in any order.
          </p>
          <img src={rsegif} alt="RSE Customizer" className="gif" />
        </div>
      </React.Fragment>
    </Container>
  </div>
);

import React from 'react';
import { RouteComponentProps } from '@reach/router';
import PageHero from '../shared/page-hero';
import Container from '../shared/page-container';
import 'styles/_global.scss';
import AsiGif from './images/asi.gif';
import Image from 'shared/image';
import Block from './images/asi-gutenblock.png';
import ComponentCode from './images/asi-component.png';
import TwoImages from '../shared/two-column-image';
import AsiBlogHero from './images/asibloghero.png';
import Asitestimonials from './images/asitestimonials.png';
import Title from 'shared/title';

export default (props: RouteComponentProps) => (
  <div className="page">
    <Title title="ASI" />

    <PageHero
      title="CSS and React"
      images="asi"
      subtitle="Architectural Systems"
    />
    <Container>
      <div className="content">
        <h3 className="project-heading">WordPress meets React.</h3>
        <p>
          <strong>Architectural Systems, Inc. (ASI)</strong> is a leading global
          resource for innovative, distinctive and sustainable interior
          finishes. They wanted a WordPress Ecommerce site using Gutenburg
          blocks.
        </p>
        <h4 className="project-heading">React</h4>
        <p>
          This was my first large project using ReactJS. This site is a headless
          WordPress project, the backend was a GraphQL api that connected to the
          WordPress database and the front end was fully react. Throughout the
          course of this project I created React components that implemented
          GraphQL queries and mutations. I learned how to manage state and why
          sometimes React’s context feature is needed. Overall this site helped
          me understand the power of frontend libraries like React vs plain
          JavaScript.
        </p>
        <a href="https://archsystems.com/" className="site-link">
          Visit Site
        </a>
      </div>
      <TwoImages
        first={Block}
        firstText="ASI Code Snippet"
        second={ComponentCode}
        secondText="ASI Code Snippet"
      />
      <TwoImages
        first={Asitestimonials}
        firstText="ASI Testimonial Component"
        second={AsiBlogHero}
        secondText="ASI Gutenberg Block"
      />
      <div className="content">
        <h4 className="project-heading">CSS</h4>
        <p>
          My job was to style the site using{' '}
          <a href="https://emotion.sh" target="_blank" rel="noopener">
            Emotion
          </a>
          . It made it easy to write reusable css with React. This was a very
          big step for me as I had to learn about GraphQL, styled components and
          React all at once.
        </p>

        <div className="image-container">
          <Image path="/projects/asi" name="asi-mobile" alt="ASI Mobile" />
        </div>

        <h4 className="project-heading">Gutenberg Blocks</h4>
        <p>
          Throughout the course of the project we developed a way to use our
          existing React components inside WordPress’s new editor, Gutenberg.
          This made it so the client could add any functionality to any area of
          their site while maintaining full control over customizing in each
          block. This is yet another thing I had to learn on the project which
          was surprising considering Gutenberg wasn’t fully released at the
          time. It was a really cool experience learning so many different
          technologies.
        </p>
        <img
          src={AsiGif}
          alt="ASI WordPress Admin Gutenburg Blocks"
          className="gif"
        />
      </div>
    </Container>
  </div>
);

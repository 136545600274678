import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title: string;
};
export default ({ title }: Props) => (
  <Helmet>
    <title>{title} - Jeanie Karina</title>
  </Helmet>
);

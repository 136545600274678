import React from 'react';

import './styles.scss';
import { RouteComponentProps } from '@reach/router';

type Props = {
  first: string;
  firstText: string;
  second: string;
  secondText: string;
  pitpay?: Boolean;
};

export default (props: Props & RouteComponentProps) => (
  <div className="two-column-images-wrapper">
    <div className={`two-column-images`}>
      <img
        src={props.first}
        alt={props.firstText}
        className={
          props.pitpay ? `pitpay two-column-image` : `two-column-image`
        }
      />
      <img
        src={props.second}
        alt={props.secondText}
        className={
          props.pitpay ? `pitpay two-column-image` : `two-column-image`
        }
      />
    </div>
  </div>
);

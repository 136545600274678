import React, { Suspense } from 'react';
import Rates from './rates';
import { RouteComponentProps, Link } from '@reach/router';

type Props = {};

export default (props: Props & RouteComponentProps) => (
  <div>
    <h1>About</h1>
    <Suspense fallback={<div>Loading...</div>}>
      <Rates />
    </Suspense>
    <Link to="/">Home</Link>
  </div>
);

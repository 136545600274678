import React, { JSXElementConstructor } from 'react';
import { RouteComponentProps } from '@reach/router';
import './styles.scss';

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export default (props: Props & RouteComponentProps) => (
  <div className="page-project-text-container text">{props.children}</div>
);

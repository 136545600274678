import React from 'react';
import Selfie from './jeanie.jpg';
import './hero.scss';
import { Motion, spring, presets } from 'react-motion';

export default () => (
  <div style={{ backgroundImage: `url(${Selfie})` }} className="hero-wrapper">
    <div className="hero-text-wrapper">
      <Motion
        defaultStyle={{ percent: 300, opacity: 0 }}
        style={{
          percent: spring(0, { stiffness: 120, damping: 30 }),
          opacity: spring(1, { stiffness: 120, damping: 60 }),
        }}
      >
        {interpolatingStyle => (
          <h1
            className="hero-text"
            style={{
              transform: `translateY(${interpolatingStyle.percent}px)`,
              opacity: interpolatingStyle.opacity,
            }}
          >
            Hola, my name is <span>Jeanie Karina</span> and I am a{' '}
            <span>frontend developer</span>.
          </h1>
        )}
      </Motion>
    </div>
  </div>
);

import React, { useState } from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import './submenu.scss';
import './header.scss';
import { Motion, spring, presets } from 'react-motion';

type submenuItem = {
  label: string;
  url: string;
};

type Props = {
  items: submenuItem[];
  title: string;
};

type ItemProps = {
  item: submenuItem;
  hide: () => void;
};

const Item = ({ item, hide }: ItemProps) => {
  const [show, setShow] = useState(false);
  console.log('item url', item.url);

  return (
    <li
      className={`drop-down__item${show ? ' drop-down-hover' : ''}`}
      onClick={hide}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      role="submenuitem"
    >
      <Link to={item.url}>{item.label}</Link>
    </li>
  );
};
export default (props: Props & RouteComponentProps) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className="menu-item"
      onClick={() => setShow(!show)}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <button
        className={`menu-link${show ? ' active' : ''}`}
        style={{
          color: show
            ? window.location.pathname === '/'
              ? '#d81a5e'
              : '#000'
            : '',
        }}
      >
        {props.title}
      </button>

      {show ? (
        <Motion
          defaultStyle={{ opacity: 0 }}
          style={{ opacity: spring(1, presets.gentle) }}
        >
          {(style) => (
            <div className="drop-down__menu-box" style={style}>
              <ul
                className="drop-down__menu"
                aria-haspopup="true"
                aria-expanded={show ? true : false}
                role="submenu"
              >
                {props.items.map((item, index) => (
                  <Item item={item} hide={() => setShow(false)} key={index} />
                ))}
              </ul>
            </div>
          )}
        </Motion>
      ) : null}
    </div>
  );
};

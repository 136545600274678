import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Hero from './hero';
import Heading from 'shared/heading-text';
import Projects from './projects';
import Skills from './skills';

export default (props: RouteComponentProps) => (
  <React.Fragment>
    <Hero />
    <Heading
      heading="About Me"
      text="I am a front end developer who builds WordPress sites from start to finish. I specialize in turning designs into code using React components and elegant CSS. I graduated in 2015 from the University of Central Florida. After school I got a job at an agency working alongside multiple teams, creating and launching various high profile projects. I have developed complex, responsive websites across various content management systems including Wordpress, Sitecore, Umbraco, Drupal, and Crownpeak."
    />
    <Heading
      heading="Fun Facts"
      text="I love to travel. I’ve been to multiple countries and love learning about new cultures. Seeing how others live around the world inspires me to be the best I can in all that I do. I am bilingual, love to dance, and I enjoy learning about space."
    />
    <Projects />
    {/* <Skills /> */}
  </React.Fragment>
);

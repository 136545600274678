import React from 'react';
import { RouteComponentProps } from '@reach/router';
import './styles.scss';
import { Motion, spring } from 'react-motion';
import Image from 'shared/image';

type Props = {
  title: string;
  images: string;
  subtitle: string;
  pitpay?: Boolean;
};

export default (props: Props & RouteComponentProps) => (
  <div className="project-hero">
    <Motion
      defaultStyle={{ percent: -100 }}
      style={{ percent: spring(0, { stiffness: 110, damping: 18 }) }}
    >
      {(interpolatingStyle) => (
        <div
          className="project-hero-text"
          style={{ transform: `translateX(${interpolatingStyle.percent}%)` }}
        >
          <div className="project-text-wrapper">
            <h1 className="project-title">{props.title}</h1>
            <h2 className="project-subtitle">{props.subtitle}</h2>
          </div>
        </div>
      )}
    </Motion>
    <Motion
      defaultStyle={{ percent: 100 }}
      style={{ percent: spring(0, { stiffness: 110, damping: 18 }) }}
    >
      {(interpolatingStyle) => (
        <div
          className="project-hero-image-wrapper"
          style={{ transform: `translateX(${interpolatingStyle.percent}%)` }}
        >
          <Image
            path={`/projects/${props.images}`}
            name={props.images}
            alt={props.images}
            className={
              props.pitpay ? `pitpay project-hero-image` : 'project-hero-image'
            }
          />
        </div>
      )}
    </Motion>
  </div>
);

import React, { useState } from 'react';
import { useQuery } from 'react-apollo-hooks';
import ratesQuery from './rates-query';

export default () => {
  const [show, setShow] = useState(true);

  const { data, error } = useQuery(ratesQuery);

  if (error) return <p>Error :(</p>;

  let items = data.allTodos.map(todo => (
    <div>
      <p>{todo.title}</p>
    </div>
  ));

  return (
    <div>
      {show ? items : null}
      <button onClick={() => setShow(!show)}>Toggle Showing</button>
    </div>
  );
};

import React from 'react';
import { RouteComponentProps } from '@reach/router';
import PageHero from '../shared/page-hero';
import Container from '../shared/page-container';
import 'styles/_global.scss';
import Events from './images/6.5-events.jpg';
import Tracks from './images/6.5-tracks.png';
import TwoImages from '../shared/two-column-image';
import Title from 'shared/title';

export default (props: RouteComponentProps) => (
  <div className="page">
    <Title title="Pit Pay" />

    <PageHero title="React Native" images="pitpay" subtitle="Pit Pay" pitpay />
    <Container>
      <div className="content">
        <h3 className="project-heading">Mobile Development</h3>
        <p>
          <strong>Pit Pay</strong> is an app that lets you buy passes to your
          favorite racing events. This app works for iOS and Android. See events
          for your favorite series and tracks quickly, or discover more with
          event filtering. It's being used for amatuer and semi-pro race events.
        </p>
        <h4 className="project-heading">React Native</h4>
        <p>
          This was my first mobile app and I was in charge of the entire mobile
          architecture. I had to implement payments using Stripe, Google Pay,
          and Apple Pay. I worked closely with the client to create a
          streamlined checkout flow, making it easy for racers to sign waivers
          and get their pit pass.
        </p>
      </div>
      <TwoImages
        first={Tracks}
        firstText="PitPay"
        second={Events}
        secondText="PitPay"
        pitpay
      />
    </Container>
  </div>
);

import React, { useState } from "react";
import { Link } from "@reach/router";
import LogoImage from "./logo.png";
import "./header.scss";
import "styles/_global.scss";
import Submenu from "./submenu";
import { RouteComponentProps } from "@reach/router";

export default (props: RouteComponentProps) => {
  return (
    <header className="header">
      <div className="container">
        <div className="header-wrapper">
          <Link to="/" className="logo">
            <img src={LogoImage} alt="Jeanie Karina Logo" />
          </Link>
          <nav
            className={`navigation${
              window.location.pathname === "/" ? "" : " navigation-inverted"
            }`}
            aria-label="Main Navigation"
            role="navigation"
          >
            <Link to="/" className="menu-link">
              Home
            </Link>
            <Submenu
              title="Projects"
              items={[
                {
                  label: "ATU",
                  url: "/projects/atu",
                },
                {
                  label: "RSE",
                  url: "/projects/rse",
                },
                {
                  label: "ASI",
                  url: "/projects/asi",
                },
                {
                  label: "Borrelli",
                  url: "/projects/drb",
                },
                {
                  label: "VineFinder",
                  url: "/projects/vinefinder",
                },
                {
                  label: "PitPay",
                  url: "/projects/pitpay",
                },
              ]}
            />
            <a href="/JeanieOrejarenaResume2021.pdf" className="menu-link">
              Resume
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

import React from 'react';
import { RouteComponentProps } from '@reach/router';
import PageHero from '../shared/page-hero';
import Container from '../shared/page-container';
import 'styles/_global.scss';
import Drb from './images/drb.gif';
import Image from 'shared/image';
import Title from 'shared/title';

export default (props: RouteComponentProps) => (
  <div className="page">
    <Title title="Dr.Borrelli" />
    <PageHero
      title="WordPress Frontend and Backend"
      images="drb"
      subtitle="Dr.Borrelli"
    />
    <Container>
      <div className="content">
        <h3 className="project-heading">
          Single page WordPress template started from scratch.
        </h3>
        <p>
          <strong>Joseph Borrelli</strong> is an Orthopedic doctor that needed
          to advertise about his field of study and practice.
        </p>
        <a href="http://orthosurgeontampa.com/" className="site-link">
          Visit Site
        </a>
        <h4 className="project-heading">CSS</h4>
        <p>
          The layout was created using a mobile-first methodology, meaning the
          smallest layout was created first, and then built upon using media
          queries to change the layout for larger screen sizes. My goal is to
          make my SCSS or POSTCSS files clean and easy to read. I use shorthands
          when I can and use variables for colors, fonts, and transitions. In
          previous experiences using variables helps making future changes
          easier. An example would be when a client wants to update their colors
          or fonts for their brand. When it comes to nesting I follow the
          Inception Rule and never nest more that 3 levels. This helps me from
          writing DOM specific styles and keeps the scss files maintainable.
        </p>
        <div className="image-container">
          <Image
            path="/projects/drb"
            name="drb-mobile"
            alt="Dr.Borrelli Demo"
          />
        </div>
        <h4 className="project-heading">Customizer</h4>
        <p>
          The majority of this site is editable in the WordPress customizer. If
          possible, I try to let the client edit everything from within this
          section. With Dr.Borrelli, the client can edit their entire site from
          here.
        </p>
        <img src={Drb} alt="Dr.Borrelli WordPress Customizer" className="gif" />
      </div>
    </Container>
  </div>
);

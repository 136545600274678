import gql from 'graphql-tag';

export default gql`
  {
    allTodos(count: 5) {
      id
      title
    }
  }
`;

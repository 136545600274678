import React from 'react';
import './projects.scss';
import Text from 'shared/heading-text';
import { Link } from '@reach/router';
import Borrelli from './borrelli.png';
import Atu from './atu.png';
import Rse from './rse.png';
import Asi from './asi.png';
import './projects.scss';

export default () => (
  <div className="projects-wrapper">
    <Text
      heading="Projects"
      text="These are examples of sites I have built at my previous employers."
      whiteText
    />
    <ul className="projects-list">
      <li className="project">
        <Link to="/projects/drb" className="project-link">
          <img
            src={Borrelli}
            alt="Dr.Borrelli Thumbnail"
            className="project-image"
          />
          <span className="project-caption">
            <h3 className="project-caption-title">Dr.Borrelli</h3>
            <h4 className="project-caption-text">WordPress Build</h4>
          </span>
        </Link>
      </li>
      <li className="project">
        <Link to="/projects/atu" className="project-link">
          <img
            src={Atu}
            alt="ATU Desktop Thumbnail"
            className="project-image"
          />
          <span className="project-caption">
            <h3 className="project-caption-title">ATU</h3>
            <h4 className="project-caption-text">WordPress Build</h4>
          </span>
        </Link>
      </li>
      <li className="project">
        <Link to="/projects/rse" className="project-link">
          <img src={Rse} alt="TEST" className="project-image" />
          <span className="project-caption">
            <h3 className="project-caption-title">RSE Ventures</h3>
            <h4 className="project-caption-text">WordPress Build</h4>
          </span>
        </Link>
      </li>
      <li className="project">
        <Link to="/projects/asi" className="project-link">
          <img
            src={Asi}
            alt="Architectural Systems Thumbnail"
            className="project-image"
          />
          <span className="project-caption">
            <h3 className="project-caption-title">Architectural Systems.</h3>
            <h4 className="project-caption-text">WordPress & React</h4>
          </span>
        </Link>
      </li>
    </ul>
  </div>
);

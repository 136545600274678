import React from 'react';
import { Router } from '@reach/router';
import { ApolloProvider } from 'react-apollo-hooks';
import Home from 'views/home';
import About from 'views/about-hooks';
import client from './apollo';
import Header from './header';
import Footer from './footer';
import '../styles/index.scss';
import Atu from 'views/projects/atu';
import RSE from 'views/projects/rse';
import ASI from 'views/projects/asi';
import DRB from 'views/projects/drb';
import Vinefinder from 'views/projects/vinefinder';
import Pitpay from 'views/projects/pitpay';

export default () => (
  <ApolloProvider client={client}>
    <Router>
      <Header default />
    </Router>
    <div className="wrapper">
      <Router style={{ height: '100%' }}>
        <Home path="/" />
        <About path="about" />
        <Atu path="projects/atu" />
        <RSE path="projects/rse" />
        <ASI path="projects/asi" />
        <DRB path="projects/drb" />
        <Vinefinder path="projects/vinefinder" />
        <Pitpay path="projects/pitpay" />
      </Router>
    </div>
    <Footer />
  </ApolloProvider>
);

import React from 'react';

type Props = {
  name: string;
  path: string;
  alt?: string;
  className?: string;
};

export default ({ name, path, className, alt }: Props) => (
  <img
    alt={alt}
    className={className}
    src={`${path}/${name}.png`}
    srcSet={`${path}/${name}.png 1x, ${path}/${name}@2x.png 2x, ${path}/${name}@3x.png 3x`}
  />
);

import React from 'react';
import { RouteComponentProps } from '@reach/router';
import PageHero from '../shared/page-hero';
import Container from '../shared/page-container';
import 'styles/_global.scss';
import ComponentCode from './images/vinefinder-codesnippet1.png';
import ComponentCode2 from './images/vinefinder-codesnippet2.png';
import Vinefinder1 from './images/vinefinder1.png';
import Vinefinder2 from './images/vinefinder2.png';
import TwoImages from '../shared/two-column-image';
import Title from 'shared/title';

export default (props: RouteComponentProps) => (
  <div className="page">
    <Title title="VineFinder" />

    <PageHero title="CSS and React" images="vinefinder" subtitle="VineFinder" />
    <Container>
      <div className="content">
        <h3 className="project-heading">Next JS</h3>
        <p>
          <strong>VineFinder</strong> merges personalized profiles with wine
          experiences for ideal trip recommendations. The wesbite was built with
          NextJS and is still under active development.
        </p>
        <h4 className="project-heading">React</h4>
        <p>
          I was part of a small team of developers that helped style the pages
          and build React components. I created easy to use component apis to
          keep all of the UI elements reusable. Using TypeScript was an awesome
          experience, I see the value and can't live without static typing.
          Overall, this project helped me become more proficient with ReactJS,
          gaining the knowledge required to build future frontend applications
          end-to-end.
        </p>
      </div>
      <TwoImages
        first={ComponentCode}
        firstText="Vinefinder Code Snippet"
        second={ComponentCode2}
        secondText="Vinefinder Code Snippet"
      />
      <TwoImages
        first={Vinefinder1}
        firstText="Vinefinder"
        second={Vinefinder2}
        secondText="Vinefinder"
      />
    </Container>
  </div>
);

import React from 'react';
import { RouteComponentProps } from '@reach/router';
import './heading-text.scss';

type Props = {
  heading: string;
  text: string;
  whiteText?: boolean;
};

export default (props: Props & RouteComponentProps) => (
  <div className="text-container">
    <div className="heading-component">
      <h3
        className="heading"
        style={props.whiteText ? { color: '#fff' } : { color: '#000' }}
      >
        {props.heading}
      </h3>
      <p
        className="text"
        style={props.whiteText ? { color: '#fff' } : { color: '#666' }}
      >
        {props.text}
      </p>
    </div>
  </div>
);
